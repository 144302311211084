import { Plus } from "lucide-react"

import { Button } from "@/components/ui/button"

const AddToRepository = () => {
  return (
    <a
      href={`https://github.com/apps/${import.meta.env.VITE_GITHUB_APP_NAME}/installations/new`}
      target="_blank"
    >
      <Button className="w-full" size="lg">
        <Plus />
        Add to a repository
      </Button>
    </a>
  )
}
export default AddToRepository
