import { ReactNode } from "react"

import AppSidebar from "@/components/sidebar/AppSidebar"
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar"

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="w-full min-h-full relative">
        <div className="px-2 py-4">
          <SidebarTrigger />
        </div>
        <div className="flex flex-col gap-y-4 min-h-full">{children}</div>
      </main>
    </SidebarProvider>
  )
}
export default Layout
