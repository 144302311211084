import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { RadioGroupItem } from "@/components/ui/radio-group"

interface Props {
  option: string
  title: string
  price: number
  description?: string
  onClick: () => void
}

const PlanCard = ({ option, title, price, description, onClick }: Props) => {
  return (
    <Card className="hover:bg-gray-50 cursor-pointer" onClick={onClick}>
      <CardHeader>
        <CardTitle className="flex gap-x-2">
          <RadioGroupItem value={option} />
          <div className="flex w-full justify-between">
            <div>{title}</div>
            <div>{price}€ / month</div>
          </div>
        </CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
    </Card>
  )
}

export default PlanCard
