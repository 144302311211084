import { useAtomValue } from "jotai"
import { Redirect, Route, Switch } from "wouter"

import Layout from "@/Layout"
import Loading from "@/components/Loading"
import CheckoutCancel from "@/features/checkout/CheckoutCancel"
import CheckoutSuccess from "@/features/checkout/CheckoutSuccess"
import NewRule from "@/features/repository/NewRule"
import Repository from "@/features/repository/Repository"
import RepositorySettings from "@/features/repository/RepositorySettings"
import Rule from "@/features/repository/Rule"
import RuleList from "@/features/rules/RuleList"
import RunList from "@/features/runs/RunList"
import Usage from "@/features/settings/Usage"
import { useFetchMe, userStateAtom } from "@/state"

const AuthenticatedApp = () => {
  const { user, isReady } = useAtomValue(userStateAtom)
  useFetchMe()
  if (!isReady) return <Loading />
  if (!user) {
    return (
      <Layout>
        <Redirect to="/login" />
      </Layout>
    )
  }

  return (
    <Layout>
      <Switch>
        <Route path="/" component={Repository} />
        <Route path="/rules" component={RuleList} />
        <Route path="/runs" component={RunList} />
        <Route path="/rules/new" component={NewRule} />
        <Route path="/rules/:ruleId" component={Rule} />
        <Route path="/repo/settings" component={RepositorySettings} />
        <Route path="/settings/usage" component={Usage} />
        <Route path="/checkout/success" component={CheckoutSuccess} />
        <Route path="/checkout/cancel" component={CheckoutCancel} />
      </Switch>
    </Layout>
  )
}

export default AuthenticatedApp
