import { ResponseUser } from "api/src/auth/user"

import { planName } from "@/components/plan/utils"
import { Badge } from "@/components/ui/badge"

interface Props {
  organisation?: ResponseUser["organisations"][number]
}

const PlanBadge = ({ organisation }: Props) => {
  if (!organisation?.subscription) {
    return null
  }
  const variant =
    (
      {
        Pro: "default",
        Trial: "outline",
        Starter: "secondary",
      } as const
    )[organisation.subscription.type] ?? ("secondary" as const)
  return (
    <Badge className="cursor-default" variant={variant}>
      {planName(organisation)}
    </Badge>
  )
}
export default PlanBadge
