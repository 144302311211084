import { ResponseUser } from "api/src/auth/user"

export const planName = (
  organisation?: ResponseUser["organisations"][number],
) => {
  if (!organisation?.subscription) {
    return ""
  }
  return (
    {
      Pro: "Pro",
      Trial: "Trial",
      Starter: "Starter",
    }[organisation.subscription.type] ?? ""
  )
}

export const PLANS = {
  Pro: {
    runs: (1000).toLocaleString(),
    rules: "50",
    price: 49,
  },
  Starter: {
    runs: "100",
    rules: "5",
    price: 19,
  },
  Trial: undefined,
} as const
