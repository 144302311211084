import { useAtomValue } from "jotai"
import { useEffect } from "react"

import { getAuthenticatedClient } from "@/api/client"
import Loading from "@/components/Loading"
import PageLayout from "@/components/PageLayout"
import { useToast } from "@/hooks/use-toast"
import { currentOrganisationAtom } from "@/state"

const CheckoutSuccess = () => {
  const { toast } = useToast()
  const organisation = useAtomValue(currentOrganisationAtom)
  const sessionId = new URLSearchParams(window.location.search).get(
    "session_id",
  )
  useEffect(() => {
    if (!organisation || !sessionId) return
    getAuthenticatedClient()
      .subscription.organisation[":organisationId"].validate.$post({
        param: { organisationId: organisation.id },
        json: {
          sessionId,
        },
      })
      .then((res) => {
        if (!res.ok) {
          toast({
            variant: "destructive",
            title: "Failed to validate subscription",
          })
          return
        }
        window.location.href = "/settings/usage?success=true"
      })
  }, [organisation, sessionId, toast])
  return (
    <PageLayout>
      <div className="flex h-full w-full items-center justify-center">
        <Loading />
      </div>
    </PageLayout>
  )
}

export default CheckoutSuccess
