import { useAtomValue } from "jotai"
import { TriangleAlert } from "lucide-react"
import { useState } from "react"
import { Link, useLocation } from "wouter"

import {
  useSearchPullRequestsQuery,
  useTriggerNewRunMutation,
} from "@/api/repo"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { useToast } from "@/hooks/use-toast"
import { currentOrganisationAtom, currentRepositoryAtom } from "@/state"

const NewRun = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false)
  const repository = useAtomValue(currentRepositoryAtom)
  const organisation = useAtomValue(currentOrganisationAtom)
  const { data: prs } = useSearchPullRequestsQuery(repository?.id, "")
  const [selected, setSelected] = useState<string>()
  const { toast } = useToast()
  const [, setLocation] = useLocation()
  const { mutate: startRun, isPending: isStarting } = useTriggerNewRunMutation(
    repository?.id,
    {
      onError: () => {
        toast({
          variant: "destructive",
          title: "Failed to start run",
        })
      },
      onSuccess: () => {
        setOpen(false)
        setLocation(`/runs`)
        toast({ title: "Run started" })
      },
    },
  )
  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger onClick={() => setOpen(true)}>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Run</DialogTitle>
          <DialogDescription>
            Select a pull request to find any rule violations in the code.
          </DialogDescription>
        </DialogHeader>
        {organisation?.subscription?.hasCredits ? (
          <Select value={selected} onValueChange={setSelected}>
            {prs?.length === 0 ? (
              <Alert className="">
                <TriangleAlert className="w-4 h-4" />
                <AlertTitle>No pull requests found.</AlertTitle>
                <AlertDescription>
                  Make sure you have at least one open pull request in the
                  repository.
                </AlertDescription>
              </Alert>
            ) : (
              <SelectTrigger>
                <SelectValue placeholder="Select a pull request" />
              </SelectTrigger>
            )}

            <SelectContent>
              {(prs ?? []).map((pr) => (
                <SelectItem key={pr.id} value={pr.number.toString()}>
                  <div className="flex gap-x-2">
                    <div>#{pr.number}</div>
                    <div>{pr.title}</div>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <Alert className="">
            <TriangleAlert className="w-4 h-4" />
            <AlertTitle>No credits available.</AlertTitle>
            <AlertDescription>
              <Link to="/settings/usage" className="text-primary font-bold">
                Please upgrade your subscription.
              </Link>
            </AlertDescription>
          </Alert>
        )}

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            onClick={() => selected && startRun(selected)}
            loading={isStarting}
            disabled={!selected || !organisation?.subscription?.hasCredits}
          >
            Start Run
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default NewRun
