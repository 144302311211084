import { useMutation } from "@tanstack/react-query"
import { SelectablePlan } from "api/src/subscription/types"
import { useAtomValue } from "jotai"
import { AreaChart } from "lucide-react"
import { useEffect, useState } from "react"

import { getAuthenticatedClient } from "@/api/client"
import PageLayout from "@/components/PageLayout"
import PageTitle from "@/components/PageTitle"
import PlanBadge from "@/components/plan/PlanBadge"
import { PLANS } from "@/components/plan/utils"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { RadioGroup } from "@/components/ui/radio-group"
import { Subtitle } from "@/components/ui/typography"
import PlanCard from "@/features/settings/PlanCard"
import { useToast } from "@/hooks/use-toast"
import { currentOrganisationAtom } from "@/state"

const Usage = () => {
  const { toast } = useToast()
  const organisation = useAtomValue(currentOrganisationAtom)
  const currentPlan = organisation?.subscription?.type
  const [option, setOption] = useState<SelectablePlan>()
  const cancelSubscriptionMutation = useMutation({
    mutationFn: async () => {
      if (!organisation) return
      return getAuthenticatedClient()
        .subscription.organisation[":organisationId"].unsubscribe.$post({
          param: { organisationId: organisation.id },
        })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to cancel subscription")
          return res.json()
        })
    },
    onSuccess: () => {
      toast({
        variant: "default",
        title: "Subscription cancelled successfully",
      })
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Failed to cancel subscription. We'll be in touch shortly",
      })
    },
  })
  useEffect(() => {
    const cancelled = new URLSearchParams(window.location.search).get(
      "cancelled",
    )
    const sessionId = new URLSearchParams(window.location.search).get(
      "session_id",
    )
    if (cancelled && sessionId && organisation) {
      getAuthenticatedClient().subscription.organisation[
        ":organisationId"
      ].session.cancel.$post({
        param: { organisationId: organisation.id },
        json: { sessionId },
      })
    }
  }, [organisation])
  const subscribeMutation = useMutation({
    mutationFn: async (plan: SelectablePlan) => {
      if (!organisation || !option) return
      return getAuthenticatedClient()
        .subscription.organisation[":organisationId"].subscribe.$post({
          param: { organisationId: organisation.id },
          json: { plan: plan },
        })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to start subscription")
          return res.json()
        })
    },
    onSuccess: (data) => {
      const sessionUrl = data?.sessionUrl
      if (sessionUrl) {
        window.location.href = sessionUrl
      } else {
        toast({
          variant: "destructive",
          title: "Failed to start subscription",
        })
      }
    },
  })
  const canSubscribe =
    organisation?.subscription?.type !== "Pro" &&
    organisation?.subscription?.type !== "Starter"
  return (
    <PageLayout title={<PageTitle title="Usage & Billing" Icon={AreaChart} />}>
      <div className="flex gap-x-4">
        <div className="flex flex-col gap-y-4 w-full">
          {currentPlan ? (
            <div className="flex flex-col gap-y-2">
              <Subtitle>Current Plan</Subtitle>
              <div className="flex gap-x-4">
                <div className="text-secondary-foreground text-sm">Plan</div>
                <PlanBadge organisation={organisation} />
                {currentPlan && PLANS[currentPlan]?.price && (
                  <div className="text-sm">
                    {PLANS[currentPlan].price}€ / month
                  </div>
                )}
              </div>

              <div className="flex gap-x-4 text-sm">
                <div>Runs used this month</div>
                <div className="font-bold">
                  {organisation?.subscription?.usedCredits} /{" "}
                  {organisation?.subscription?.credits}
                </div>
              </div>
              {organisation?.subscription?.renewsAt && (
                <div className="flex gap-x-4 text-sm">
                  <div>Renews on </div>
                  <strong>
                    {new Date(
                      organisation.subscription.renewsAt,
                    ).toLocaleDateString()}
                  </strong>
                </div>
              )}
              {organisation?.subscription?.expiresAt && (
                <div className="flex gap-x-4 text-sm">
                  <div>Expires on </div>
                  <strong>
                    {new Date(
                      organisation.subscription.expiresAt,
                    ).toLocaleDateString()}
                  </strong>
                </div>
              )}
            </div>
          ) : undefined}
          {canSubscribe && (
            <div className="flex flex-col gap-y-2">
              <Subtitle>Subscribe</Subtitle>
              <RadioGroup value={option}>
                {currentPlan !== "Pro" ? (
                  <PlanCard
                    option={SelectablePlan.Pro}
                    title="Pro Plan"
                    price={49}
                    description="50 rules – 1000 Analysis Runs / month"
                    onClick={() => setOption(SelectablePlan.Pro)}
                  />
                ) : undefined}
                {currentPlan !== "Starter" ? (
                  <PlanCard
                    option={SelectablePlan.Starter}
                    title="Starter Plan"
                    price={19}
                    description="5 rules – 100 Analysis Runs / month"
                    onClick={() => setOption(SelectablePlan.Starter)}
                  />
                ) : undefined}
              </RadioGroup>
            </div>
          )}
          <div className="flex gap-x-2 justify-end">
            {organisation?.subscription?.type === "Pro" ||
            organisation?.subscription?.type === "Starter" ? (
              <Dialog>
                <DialogTrigger>
                  <Button
                    variant="destructive"
                    loading={cancelSubscriptionMutation.isPending}
                  >
                    Cancel Subscription
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>
                      Are you sure you want to cancel your subscription?
                    </DialogTitle>
                    <DialogDescription>
                      Your subscription will be cancelled and you will lose
                      access to your current plan.
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <DialogClose asChild>
                      <Button variant="secondary">No</Button>
                    </DialogClose>
                    <Button
                      variant="destructive"
                      onClick={() => cancelSubscriptionMutation.mutate()}
                    >
                      Yes, cancel my subscription
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            ) : undefined}
            {canSubscribe && (
              <Button
                disabled={!option}
                loading={subscribeMutation.isPending}
                onClick={() => {
                  if (!option) return
                  subscribeMutation.mutate(option)
                }}
              >
                Subscribe
              </Button>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Usage
